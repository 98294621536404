@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

.row {
  @media #{$desktop} {
    margin: 0 -30px; } }

.work,.service,.team {
  display: block;
  margin-bottom: 15px;
  @media #{$desktop} {
    padding: 0 30px; }
  figure {
    display: block;
    overflow: hidden;
    &:after {
      content: "";
      display: table;
      width: 100%;
      padding-bottom: 100%; }
    img {
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%,auto); } }
  figcaption {
    padding: 10px 0;
    p {
      @include cr($color_text);
      text-align: center;
      font-size: 16px;
      height: 40px;
      line-height: 20px;
      span {
        display: block;
        @include cr($color_hover); } } } }

.team {
  figure {
    &:after {
      padding-bottom: 130%; } } }

.select-links {
  z-index: 4;
  margin: {
    bottom: 45px; }
  padding: 5px;
  @media #{$tablet} {
    padding: 5px 40px 5px 5px;
    border: solid 1px $color_main; }
  .list {
    @media #{$desktop} {
      display: block!important; }
    @media #{$tablet} {
      border: solid 1px $color_main;
      padding: 20px 0px;
      position: absolute;
      top: 100%;
      left: 0;
      @include size(100%,auto);
      background-color: #fff;
      display: none; }
    a {
      display: inline-block;
      padding: 2px 20px;
      color: $color_text;
      margin-right: 1vw;
      border-radius: 2px;
      @include t(.3s);
      @media #{$desktop} {
        &:hover,&.active {
          background-color: $color_main;
          @include cr(#fff); } }
      @media #{$tablet} {
        display: block;
        margin-right: 0; } } }
  #select-list {
    @include size(0);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .now {
    display: none;
    margin: 0;
    @media #{$tablet} {
      display: block;
      padding: 7px 20px;
      @include cr($color_main);
      background-color: #fff;
      cursor: pointer;
      &.active {
        background-color: $color_main;
        @include cr(#fff); }
      &:after {
        content: "";
        display: block;
        @include size(0);
        border: {
          top: solid 10px $color_main;
          left: solid 6px transparent;
          bottom: solid 10px transparent;
          right: solid 6px transparent; }
        position: absolute;
        left: calc(100% + 14px);
        bottom: 50%;
        transform: translateY(75%); } } } }

.work-detail {
  &>div {
    @media #{$desktop} {
      padding: 0 30px; } }
  figure {
    @media #{$tablet} {
      margin-bottom: 40px;
      max-width: 350px; }
    img {
      display: block;
      width: 100%; } }
  .info {
    h2 {
      @include cr($color_main);
      font-size: 25px;
      margin-bottom: 50px; }
    p {
      margin-bottom: 30px;
      span {
        display: block;
        &:first-of-type {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 5px;
          line-height: 16px; }
        &:last-of-type {
          font-size: 14px; } } } } }


.team-list {
  padding: 50px 0;
  margin: 0 auto;
  max-width: 1280px;
  width: 70vw;
  @media #{$phone} {
    width: 100%;
    padding: 50px 30px; }
  a {
    display: block;
    @include cr($color_text); }
  h2 {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: solid 1px $color_main;
    @include cr($color_main);
    font-size: 18px;
    &:after {
      content: "";
      display: block;
      @include size(50px,2px);
      background-color: $color_main;
      position: absolute;
      top: 100%;
      left: 0; } }
  .main-people {
    width: 25%;
    @media #{$tablet} {
      width: 100%;
      margin-bottom: 30px; } }
  .other-people {
    width: 75%;
    padding-left: 5vw;
    @media #{$tablet} {
      width: 100%;
      padding-left: 0; }
    @include clearfix;
    .group {
      width: 33%;
      float: left;
      @media #{$phone} {
        width: 50%;
        margin-bottom: 20px; } } } }

.team-detail {
  [class^="col-"] {
    padding: 0 30px; }
  figure {
    margin-bottom: 20px;
    img {
      display: block;
      width: 100%; } }
  h2 {
    margin-bottom: 5px;
    font-size: 20px;
    @include cr($color_hover);
    span {
      display: block;
      margin-top: 5px;
      @include cr($color_text);
      font-size: 16px; }
    &.name {
      margin-bottom: 30px; } }

  p {
    @include cr($color_text); } }
