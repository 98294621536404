@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

* {
  position: relative;
  vertical-align: middle;
  @include main_font; }

h1,h2,h3,h4,h5,h6,p {
  margin: 0; }

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none; } }

body {
  animation: fade 1.5s 1.5s both; }

// bg
[data-bg] {
  display: block;
  @include size(100%,auto);
  background-size: cover;
  background-repeat: no-repeat; }

//flex
.flex {
  &.still {
    display: flex; }
  &.fw {
    flex-wrap: wrap; }
  &.ai {
    align-items: center; }
  &.jc {
    justify-content: center; }
  &.reverse {
    flex-direction: row-reverse; } }

.page-container {
  padding: 30px; }

.container {
  padding: 0;
  width: 70vw;
  max-width: 1280px;
  @media #{$phone} {
    width: 100%; } }

h2.title {
  display: table;
  margin: 0 auto 40px auto;
  text-align: center;
  @include cr($color_main);
  .mt {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 3px; }
  .st {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 16px;
    border-bottom: solid 1px $color_main; } }

.info {
  font-weight: 500;
  p {
    font-size: 14px;
    @include cr(#333);
    line-height: 25px; }
  .list {
    padding-left: 30px;
    li {
      list-style-type: disc;
      @include cr($color_text);
      font-size: 14px;
      line-height: 25px; } } }

.info.text-center {
  font-weight: bold; }

a.btn {
  display: table;
  padding: 3px 10px;
  margin: {
    top: 30px;
    bottom: 0;
    left: auto;
    right: auto; }
  @include cr($color_main);
  border: solid 1px $color_main;
  border-radius: 4px;
  font-size: 14px;
  @include t(.5s);
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-left: 5px;
    @include size(14px);
    background-image: url('/wp-content/uploads/ic_arrow.svg');
    background-repeat: no-repeat;
    background-position: center center; }
  &:hover {
    @include cr($color_main);
    box-shadow: 2px 2px 5px #999; } }

.o-link {
  display: table;
  margin: 40px auto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  @include cr(#333);
  @include t(.5s);
  &:hover {
    @include cr($color_main); } }

.mobile-hide {
  @media #{$phone} {
    display: none; } }

.banner {
  height: 400px;
  background-position: center bottom;
  @media #{$phone} {
    height: 250px; } }

h2.page-title {
  font-size: 23px;
  @include cr($color_main);
  letter-spacing: 4px;
  margin-bottom: 30px;
  &:after {
    content: "";
    display: table;
    margin-top: 10px;
    @include size(50px,2px);
    background-color: $color_main; } }

@media screen and (min-width: 768px) {
  .flex {
    display: flex; } }

