@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

.swiper-slide {
  img {
    display: block;
    width: 100%; } }

.carousel-1 {
  padding-bottom: 60px;
  .content {
    position: absolute;
    bottom: 50%;
    right: 12%;
    transform: translateY(50%);
    text-align: right;
    h2,p {
      @include cr($color_main);
      @include t(2s);
      font-weight: bold;
      left: -50px;
      opacity: 0; }
    h2 {
      font-size: 45px;
      border-bottom: solid 1px $color_main;
      padding-left: 50px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      @media #{$phone} {
        font-size: 35px; }
      @media #{$phone-xs} {
        font-size: 25px; } }
    p {
      font-size: 25px;
      letter-spacing: 2px;
      @media #{$phone} {
        font-size: 20px; }
      @media #{$phone-xs} {
        font-size: 14px; } } }
  .swiper-slide-active {
    .content {
      h2,p {
        left: 0;
        opacity: 1; }
      h2 {
        transition-delay: 1s; }
      p {
        transition-delay: 1.5s; } } }

  .swiper-pagination {
    bottom: 20px;
    .swiper-pagination-bullet {
      background-color: $color_main;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: $color_hover; } } } }
