@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";


.boss {
  margin-top: 13vw;
  margin-bottom: 100px;
  background-color: #ebebeb;
  @media #{$phone} {
    margin-bottom: 0;
    margin-top: 0; }
  .info {
    padding-left: 35%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 5%;
    @media #{$phone} {
      padding-left: 5%;
      margin-top: 0px; }
    h2.name {
      position: absolute;
      bottom: 100%;
      margin-bottom: 10px;
      font-size: 20px;
      @include cr($color_hover);
      @media #{$phone} {
        // margin-bottom: -10px
        font-size: 18px;
        position: relative; } }
    a.btn {
      background-color: $color_hover;
      @include cr(#fff);
      margin: {
        top: 20px;
        left: 0; }
      &:after {
        display: none; } } }
  figure {
    width: 25%;
    position: absolute;
    bottom: 50%;
    left: 5%;
    transform: translateY(40%);
    z-index: 2;
    @media #{$phone} {
      position: relative;
      transform: initial;
      left: 0;
      bottom: 0;
      width: 40%;
      padding-top: 20px;
      margin-left: 5%; }
    img {
      display: block;
      width: 100%; } } }
