@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";


.contact-row {
  @include clearfix; }

.contact-list {
  float: left;
  width: 55%;
  padding-right: 5%;
  @media #{$tablet} {
    float: none;
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0; }
  .list {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0; }
    h2 {
      font-size: 20px;
      @include cr($color_text);
      border-bottom: solid 1px $color_text;
      padding-bottom: 15px;
      margin-bottom: 15px;
      @include clearfix;
      @media #{$phone} {
        font-size: 17px;
        line-height: 28px;
        padding-bottom: 10px; }
      a.btn {
        margin: 0;
        float: right; } }
    li {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      span,a {
        display: block;
        font-size: 14px;
        color: $color_text; }
      span:first-of-type {
        min-width: 50px; } } } }

.contact-form {
  width: 45%;
  float: left;
  @media #{$tablet} {
    float: none;
    width: 100%; }
  div.wpforms-container-full .wpforms-form .wpforms-field {
    padding: 0;
    margin-bottom: 10px; }
  div.wpforms-container-full .wpforms-form .wpforms-field-label {
    font-size: 14px;
    @include cr($color_hover);
    letter-spacing: 3px; }
  div.wpforms-container-full .wpforms-form input[type=date], div.wpforms-container-full .wpforms-form input[type=datetime], div.wpforms-container-full .wpforms-form input[type=datetime-local], div.wpforms-container-full .wpforms-form input[type=email], div.wpforms-container-full .wpforms-form input[type=month], div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea {
    border-color: $color_hover; }
  div.wpforms-container-full .wpforms-form button[type=submit] {
    float: right;
    padding: 5px 25px;
    background-color: $color_hover;
    color: #fff;
    border: initial!important;
    &:hover {
      background-color: $color_main; } } }
