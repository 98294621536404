@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";


#footer {
  background: #42291b;
  padding-bottom: 15px;
  .container {
    width: 80vw; }
  .row {
    display: flex;
    align-items: center;
    @media #{$phone} {
      display: block; } }
  .f-info {
    @media #{$phone} {
      margin-bottom: 20px; }
    h3 {
      margin-bottom: 20px;
      font-size: 16px; }
    .list {
      li {
        display: flex;
        margin-bottom: 5px;
        &:last-of-type {
          margin-bottom: 0;
          a {
            display: inline-block;
            margin: 0;
            @media #{$phone} {
              display: block; }
            &:after {
              content: "MAP";
              background-image: url("/wp-content/uploads/ic_arrow.svg");
              background-size: 15px;
              background-repeat: no-repeat;
              background-position: 90% center;
              display: table;
              background-color: #fff;
              color: #42291b;
              padding: 0px 25px 0px 5px;
              margin-left: 10px;
              border-radius: 4px;
              @media #{$phone} {
                margin-left: 0;
                margin-top: 5px; } } } }

        span,a,img {
          display: inline-block;
          font-size: 14px;
          line-height: 14px;
          color: #fff;
          line-height: 20px; }
        span {
          &:first-of-type {
            min-width: 40px; } }
        img {
          width: 25px;
          height: 25px;
          letter-spacing: 2px;
          margin-right: 7px; } } } }
  .map {
    display: block;
    padding-bottom: 30%;
    background-image: url('/wp-content/uploads/map.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }

  p.copy {
    text-align: center;
    padding-top: 30px;
    a {
      display: table;
      margin: 0 auto; }
    img {
      display: block;
      margin: 0 auto;
      @include size(auto,12px); } } }

