@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

body {
  padding-top: 75px;
  @media #{$phone} {
    padding-top: 46.3px; } }

body.menu-fixed {
  #header,#mobile-nav-toggle {
    position: fixed;
    animation: fadeDown 1.5s both; } }

#header {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%,auto);
  background-color: rgba(#fff,.8);
  z-index: 100; }

.main-nav-right .site-logo {
  margin-top: 0;
  margin-bottom: 0;
  z-index: 2;
  a {
    display: block;
    img {
      display: block;
      @include size(395px,73.5px);
      @media #{$phone} {
        @include size(250px,auto); } } } }

#header-container {
  max-width: 1280px;
  padding: 0;
  width: 95vw; }


#main-nav {
  @media screen and (max-width: 1000px) {
    display: none; }
  a {
    display: block;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: bold;
    @include main_font;
    @include t(.5s); }
  &>li>a {
    font-size: 18px;
    padding: 26.5px 1.8vw!important;
    color: $color_main;
    @media screen and (max-width: 1160px) {
      padding: 26.5px 1vw!important; } }

  &>li {
    &:hover>a {
      background-color: $color_main;
      color: #fff; } }
  .sub-menu {
    width: 100%;
    li {
      min-width: 0;
      border: initial;
      a {
        display: block;
        text-align: center;
        @include cr($color_text);
        padding-left: 0;
        padding-right: 0;
        white-space: normal; }
      &:hover {
        background-color: #e4e4e4; } } } }

#mobile-nav-toggle {
  padding: 0;
  top: 25px;
  @media screen and (max-width: 1000px) {
    display: block; }
  @media #{$phone} {
    top: 11px; } }
