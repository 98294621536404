@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

.info-lp {
  .info {
    width: 45%;
    background-color: #eee;
    padding: 30px; }
  figure {
    width: 55%;
    display: block;
    img {
      display: block;
      width: 100%; } }
  @media #{$tablet} {
    .info,figure {
      width: 100%; } } }

.kc_wrapper.ui-tabs {
  display: block;
  margin: 0 auto;
  max-width: 1270px;
  width: 70vw;
  @media #{$tablet} {
    width: 100%; } }

.kc_tabs>.kc_wrapper>.kc_tabs_nav {
  display: flex;
  padding: 40px 0 20px 0;
  margin-bottom: 20px;
  background: initial;
  justify-content: center;
  min-height: 0;
  float: none;
  border-bottom: solid 1px $color_main!important; }


.kc_tabs_nav>li {
  border: solid 1px $color_main!important;
  @include cr($color_main);
  padding: 5px 10px;
  min-width: 110px;
  text-align: center;
  margin: 0 20px;
  @include t(.3s);
  line-height: 20px;
  float: none;
  height: auto;
  @media #{$phone} {
    margin: 0 5px; }
  &.ui-tabs-active,&:hover {
    background-color: $color_main;
    color: #fff!important; } }

.kc_tabs_nav~.kc_tab.ui-tabs-body-active {
  background: initial;
  float: none;
  .service {
    animation: fade 1s both; } }

.kc_tabs_nav~.kc_tab .kc_tab_content {
  padding: 30px;
  @include clearfix; }

.fancybox-navigation {
  position: initial; }

.fancybox-button--share {
  display: none; }

.fancybox-slide--iframe .fancybox-content {
  margin: 0;
  height: 0;
  padding-bottom: 37%;
  width: 65vw;
  @media #{$tablet} {
    width: 80vw;
    padding-bottom: 50%; }
  iframe.fancybox-iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    @include size(100%,100%); } }

//下載
.download-list {
  max-width: 80%;
  margin: 0 auto;
  @media #{$phone} {
    max-width: 100%; }
  .download {
    margin-bottom: 20px;
    @include clearfix;
    span,a {
      display: block;
      float: left;
      padding: 5px 0; }
    span {
      width: calc(100% - 150px);
      @include cr($color_text); }
    a {
      width: 150px;
      background-color: $color_main;
      text-align: center;
      color: #fff; } } }
